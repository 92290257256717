<template>
  <v-dialog v-model="show" max-width="500px">
    <v-card>
      <v-card-title class="text-h5"
        >Are you sure you want to delete this item?</v-card-title
      >
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
        <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "confirmDelete",
  props: ["item", "deleteAction"],
  computed: {
    show() {
      return this.item;
    },
  },
  methods: {
    deleteItemConfirm() {
      let vm = this;

      vm.$store.dispatch(this.deleteAction, this.item.id).then(() => {
        vm.close();
      });
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped></style>
